import { BrowserRouter, Routes, Route } from "react-router-dom"
import HeroSection from "./pages/heroSection/HeroSection"
import Contact from "./pages/contact/Contact"
import Product from "./pages/product/Product"
import ProductDetail from "./pages/productDetails/ProductDetail"

const AllPages = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HeroSection />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/product" element={<Product />}></Route>
        <Route path={`/product/:id`} element={<ProductDetail />}>
          {" "}
        </Route>
      </Routes>
    </BrowserRouter>
  </>
)

export default AllPages
