import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"

import "../../styles/partials/hero.scss"

import BlockIllustration from "../../images/block-arrow.png"
import InCashew from "../../images/cashew kernels.jpg"
import Cashew from "../../images/cashew kernels.jpg"
import Gallstones from "../../images/gallstones.png"
import TigerNutSack from "../../images/tiger-nuts-in-sack.jpeg"
import TigerNut from "../../images/tiger-nuts-open.jpeg"
import Garri from "../../images/white-garri.jpeg"

const HeroSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    lazyLoad: "progressive",
    numbers: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <Navbar />
      <div className="container hero">
        <div className="hero_primaryText">
          <h1>
            Re-imagine Agricultural Exports to increase value through excellent
            quality of agricultural produce.
          </h1>
        </div>
        <div className="hero_secondaryText">
          <p>
            Trinity Golden Crown Ltd specialises in the exportation of
            agricultural produce. We are known for the best quality of agro
            products and ensure mutual understanding between ourselves and
            intending part(ies). We deal majorly in the semi-processed and
            un-processed agricultural products. Some of such products are:
          </p>
          <p>Sesame Seeds</p>
          <p>Shea Butter</p>
          <p>Yellow Garri</p>
          <p>White Garri</p>
          {/* <a href="#!" className="home_Btn">
            Learn more
          </a> */}
        </div>
      </div>

      <section className="container hero_one">
        <div className="hero_one-text">
          <h1 className="title">Agro-export Products</h1>
          <p className="info">
            We are driving a revolution in fresh produce supply chain across
            Nigeria. <br /> We deliver fresh farm produce straight from the farm
            to critical demand <br />
            clusters across Nigeria including restaurants, resellers, and bulk
            family users.
          </p>
          <a href="/product" className="btn">
            Explore Now
          </a>
        </div>
        <div className="hero_one-img">
          <img src={BlockIllustration} alt="block-img" className="block-img" />
        </div>
      </section>

      <section className="container hero-two">
        <div className="heroProduct_text">
          <h1>Explore our products</h1>
        </div>
        <div>
          <Slider {...settings}>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img className="home4-img" src={Cashew} alt="alternative" />
                  <p className="text-right">Raw cashew nuts</p>
                </a>
              </div>
            </div>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img
                    className="home4-img"
                    src={Gallstones}
                    alt="alternative"
                  />
                  <p className="text-right">Cow Gallstones</p>
                </a>
              </div>
            </div>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img className="home4-img" src={Garri} alt="alternative" />
                  <p className="text-right">White Garri</p>
                </a>
              </div>
            </div>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img className="home4-img" src={InCashew} alt="alternative" />
                  <p className="text-right">Processed Cashew</p>
                </a>
              </div>
            </div>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img className="home4-img" src={TigerNut} alt="alternative" />
                  <p className="text-right">Tiger Nut</p>
                </a>
              </div>
            </div>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img
                    className="home4-img"
                    src={TigerNutSack}
                    alt="alternative"
                  />
                  <p className="text-right">Sacked Tiger Nut</p>
                </a>
              </div>
            </div>
            <div>
              <div className="image-wrapper">
                <a href="/product">
                  <img className="home4-img" src={InCashew} alt="alternative" />
                  <p className="text-right">Processed Cashew</p>
                </a>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default HeroSection
