import { useState } from "react";
import axios from "axios";
import { FiHome, FiPhone, FiMail } from "react-icons/fi";
import "../../styles/partials/contact.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(fullName, companyName, email, whatsApp, enquiry);

    const data = {
      Name: fullName,
      CompanyName: companyName,
      Email: email,
      WhatsApp: whatsApp,
      Enquiry: enquiry,
    };

    try {
      axios
        .post(
          "https://sheet.best/api/sheets/3fbc530d-df02-4d67-9b83-91fb55d67e6a",
          data
        )
        .then((response) => {
          console.log(response);
          const message = `Name: ${fullName}\nCompany: ${companyName}\nEmail: ${email}\nWhatsApp: ${`08111784179`}\nEnquiry: ${enquiry}`;
          const whatsappUrl = `https://api.whatsapp.com/send?phone=08111784179&text=${encodeURIComponent(
            message
          )}`;
          window.location.href = whatsappUrl;
          // clear form fields
          setFullName("");
          setCompanyName("");
          setEmail("");
          setWhatsApp("");
          setEnquiry("");
          setMessage("User Information gotten successfully!");
        });
    } catch (err) {
      setMessage("Some error occurred");
    }
  };

  return (
    <>
      <Navbar />
      <section className="section">
        <div className="section-header">
          <div className="container">
            <h2>Contact Us</h2>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <FiHome className="i" />
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                    1. Chief Bode Moses street, Ibafo, <br />
                    Lagos-Ibadan express- way, Obafemi-owode LGA,
                    <br />
                    Ogun State, Nigeria
                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <FiPhone className="i" />
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>+2348165224307, +2348111784179</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <FiMail className="i" />
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>enquiries@trinitygoldencrownltd.com, </p>
                  <p>trinitygoldencrownltd@outlook.com</p>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <form id="contact-form" onSubmit={handleSubmit}>
                <h2>Send Message</h2>
                <div className="input-box">
                  <input
                    type="text"
                    required="true"
                    name="fullName"
                    placeholder="Full Name"
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                  />
                  <span></span>
                </div>

                <div className="input-box">
                  <input
                    type="text"
                    required="true"
                    name="companyName"
                    placeholder="Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                  />
                  <span></span>
                </div>

                <div className="input-box">
                  <input
                    type="email"
                    required="true"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <span></span>
                </div>

                <div className="input-box">
                  <input
                    type="tel"
                    required="true"
                    name="whatsApp"
                    placeholder="WhatsApp / Telegram Number"
                    onChange={(e) => setWhatsApp(e.target.value)}
                    value={whatsApp}
                  />
                  <span></span>
                </div>

                <div className="input-box">
                  <textarea
                    required="true"
                    name="enquiry"
                    placeholder="Enquiry"
                    onChange={(e) => setEnquiry(e.target.value)}
                    value={enquiry}></textarea>
                  <span></span>
                </div>

                <div className="input-box">
                  <input type="submit" value="Send" name="" />

                  <div className="message">
                    {message ? <p>{message}</p> : null}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
