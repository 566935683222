import TigerNut from "../../images/tiger-nuts-open.jpeg"
// import TigerNutSack from "../../images/tiger-nuts-in-sack.jpeg"
import Garri from "../../images/white-garri.jpeg"
import Gallstones from "../../images/gallstones.png"
import Cashew from "../../images/cashew kernels.jpg"
// import InCashew from "../../images/cashew kernels.jpg"

const products = [
  {
    id: 1,
    name: "Processed Cashew Kernels",
    image: Cashew,
    description: "We export the SW240, W180 cashew kernels",
    price: 11000,
  },
  //   {
  //     id: 2,
  //     name: "Cashew Inside",
  //     image: InCashew,
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //     price: 19.99,
  //   },
  {
    id: 2,
    name: "Cow Gallstones",
    image: Gallstones,
    description: "We sell Cow Gallstones",
    price: 79.99,
  },
  {
    id: 3,
    name: "White Garri",
    image: Garri,
    description:
      "Our White Garri is very clean, free from impurities and is sieved to the finest qquality",
    price: 39.99,
  },
  {
    id: 4,
    name: "Tiger Nut",
    image: TigerNut,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    price: 29.99,
  },
  //   {
  //     id: 5,
  //     name: "Black Stone flower",
  //     image: TigerNutSack,
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //     price: 29.99,
  //   },
]

export default products
